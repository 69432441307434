import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import './Faq.scss'

const Faq = () => {
  const [visibility, setVisibility] = useState({})

  const FAQs = [
    {
      title: '1. How do I install Ovation?',
      content: (
        <>
          Once your trial or subscription is active, you can install Ovation on your PC using the installer downloaded
          from our&nbsp;
          <a href="https://portal.ovationvr.com/">web portal</a>.
        </>
      )
    },
    {
      title: `2. How many PCs can I install Ovation on? Do I need a license for each install?`,
      content: (
        <>
          You can install Ovation on as many PCs as you want. Instead of limiting installations, Ovation uses a
          concurrent user licensing model, which means that after launching the software a user can only sign in and use
          it if there is an available license that is not currently being used.
          <br />
          <br />
          Individual subscriptions begin with one license and cannot add more. Only the purchaser of the Individual
          subscription is permitted to sign in and use Ovation.
          <br />
          <br />
          Organization subscriptions begin with 1 VR license and 5 monitor licenses but can purchase additional licenses
          as needed.
          <br />
          <br />
          An example where additional licenses would be needed is a school that has 4 VR stations, where students often
          use Ovation at the same time. In this case, the school would need to increase its VR license count from 1 to
          4.
        </>
      )
    },
    {
      title: `3. What are VR & Monitor Licenses?`,
      content: (
        <>
          Ovation can be used with a virtual reality headset or a computer monitor. These are referred to as VR Mode and
          Monitor Mode. To use Ovation in these modes, a user must have the proper license type.
          <br />
          <br />
          Monitor Mode has fewer features in comparison to VR Mode and is available at a lower price.
          <br />
          <br />A VR license allows a user to use Ovation in either VR or Monitor Mode. A Monitor license only allows a
          user to use Ovation in Monitor Mode.
        </>
      )
    },
    {
      title: `4. Can Ovation be installed on standalone or mobile VR headsets?`,
      content: (
        <>
          No. Ovation is a demanding application that requires a powerful VR-Ready PC. It will not run on the Oculus
          Go/Quest, Vive Focus, or similar hardware. One exception is a Quest (1 or 2) that is using Link or AirLink to
          connect to a VR-Ready PC. Please visit our&nbsp;
          <a className="gtm-outbound-link-documentation-hardware" href="https://docs.ovationvr.com/ovation/hardware">
            hardware documentation page
          </a>
          &nbsp;for more details.
        </>
      )
    },
    {
      title: `5. Do you provide support with installation?`,
      content: (
        <>
          Of course! If you have any issues installing or launching Ovation, please contact us at&nbsp;
          <a href="mailto:support@ovationvr.com">support@ovationvr.com</a>.
        </>
      )
    },
    {
      title: `6. Does Ovation support languages other than English?`,
      content: `Ovation's user interface is only available in English; however, while delivering a practice speech in Ovation, you can choose to speak one of 8 supported languages: English, Spanish, French,        German, Chinese, Japanese, Korean, and Arabic.`
    },
    {
      title: `7. Do you provide support for organization setup and training?`,
      content: (
        <>
          We provide setup and training to Organization subscribers for an additional cost. For more details and
          pricing, please email us at&nbsp;
          <a href="mailto:support@ovationvr.com">support@ovationvr.com</a> or call the phone number listed on the
          Organization page once signed in to the web portal.
          <br />
          <br />
          We highly recommend downloading our&nbsp;
          <a
            href="https://docs.ovationvr.com/ovation/instructions/organization-information-packet"
            target="_blank"
            rel="noreferrer"
          >
            Organization Information Packet
          </a>
          , which contains how-tos and tips for successful deployment.
        </>
      )
    },
    {
      title: `8. What is meant by "Organization Members" and "unlimited member accounts"?`,
      content: (
        <>
          An Organization trial/subscription should be activated by the person who will be responsible for the
          Organization. Once the subscription is active, the Admin can then invite an unlimited number of Organization
          Members to join the Organization. The Admin and all Members are able to sign in to and use the Ovation
          application.
          <br />
          <br />
          We highly recommend downloading our&nbsp;
          <a
            href="https://docs.ovationvr.com/ovation/instructions/organization-information-packet"
            target="_blank"
            rel="noreferrer"
          >
            Organization Information Packet
          </a>
          , which contains how-tos and tips for successful deployment.
        </>
      )
    },
    {
      title: `9. How do I invite Organization Members to my organization?`,
      content: (
        <>
          The Organization Admin, and Organization Members with the appropriate role, can go to the Organization/Members
          page of the web portal and email a unique activation code to prospective Organization Members. The email
          recipient will enter the unique activation code during account creation and activation.
          <br />
          <br />
          We highly recommend downloading our&nbsp;
          <a
            href="https://docs.ovationvr.com/ovation/instructions/organization-information-packet"
            target="_blank"
            rel="noreferrer"
          >
            Organization Information Packet
          </a>
          , which contains how-tos and tips for successful deployment.
        </>
      )
    },
    {
      title: `10. Is Ovation available for purchase elsewhere (Steam, Oculus, etc.)?`,
      content: `Ovation is only available for purchase from our website.`
    },
    {
      title: `11. How do I apply the Education/Non-profit discount?`,
      content: `Prospective Organization customers can check the “Organization is a school or NPO” checkbox when submitting an application for a free trial. We will then review the application and, if necessary, ask follow-up questions to determine eligibility for the discount. If eligible, a member of the Ovation staff will apply the discount before the trial is activated.`
    },
    {
      title: `12. When am I charged?`,
      content: `Your credit card or digital wallet (e.g. Google Pay) will be charged when your trial period ends, if billing information has been added. If no trial is available, you will be charged when your subscription is activated. You will then be charged at each subsequent billing interval (monthly or annually).`
    },
    {
      title: `13. How and when can I cancel my subscription?`,
      content: `You can cancel your subscription at any time on the Billing page of the Ovation web portal. If your subscription is canceled before your trial ends, you will not be charged. Once your trial period has ended you can choose to either cancel your subscription at the next billing renewal date or cancel immediately. If canceled at the next billing renewal date, you can continue to use Ovation for the remaining time left in your subscription. If canceled immediately, you will no longer be able to use Ovation and your subscription will be deleted. Any remaining time left on your subscription will be forfeited. Unused time is not refunded.`
    },
    {
      title: `14. Do you provide refunds?`,
      content: `Refunds are not available in most cases. Please use the trial period to ensure your satisfaction with Ovation.`
    },
    {
      title: `15. I have a question not answered here, can you help?`,
      content: (
        <>
          You bet! Email us at&nbsp;
          <a href="mailto:contact@ovationvr.com">contact@ovationvr.com</a> and we'll respond as quickly as we can.
        </>
      )
    }
  ]

  const toggleItem = (index) => {
    if (!visibility[index]) {
      // if the dropdown is being opened
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: `gtm-interactive-element-faq-open-${index}`
      })
    }
    setVisibility((v) => ({ ...v, [index]: !v[index] }))
  }

  return (
    <div className="press-wrapper">
      {/* {FAQs.map((faq, index) => (
        <div key={faq.title} className="faq-box">
          <div className="faq-title" onClick={() => toggleItem(index)}>
            <FontAwesomeIcon size="xs" icon={visibility[index] ? faCaretDown : faCaretRight} />
            &nbsp;{faq.title}
          </div>
          <div className={`faq-body${visibility[index] ? ' active' : ''}`}>{faq.content}</div>
        </div>
      ))} */}
      <h4>
        <a className="press-link" target="_blank" rel="noopener" href="https://docs.ovationvr.com/ovation/faq">
          View Ovation FAQs on our Documentation website.
        </a>
      </h4>
    </div>
  )
}

export default Faq
