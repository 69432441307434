import React from 'react'
import Helmet from 'react-helmet'

import Faq from '../components/Faq/Faq'
import { metaData } from '../meta'

const FaqIndex = () => (
  <>
    <Helmet title={metaData.faq.title}>
      <meta name="description" content={metaData.faq.description} />
      <meta name="keywords" content={metaData.faq.keywords} />
    </Helmet>
    <Faq />
  </>
)

export default FaqIndex
